import { Component, ViewEncapsulation } from '@angular/core';
import { AppUrlConfig } from '../../config/app-url.config';

/**
 * NotFoundComponent show when not available for user
 * */
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotFoundComponent {
  readonly HOME_URL = AppUrlConfig.HOME_URL;
}
