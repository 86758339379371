<div class="not-found-container">
    <div class="not-found-card">
        <div class="not-found-number">404</div>
        <div class="not-found-title">{{ 'NotFound.Title' | translate }}</div>
        <div>{{ 'NotFound.Description' | translate }}</div>

        <button [routerLink]="HOME_URL" class="btn btn-primary mt-4 navigate-home-button">
            {{ 'NotFound.Back' | translate }}
        </button>
    </div>
</div>